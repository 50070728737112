import React, { useLayoutEffect } from "react";
import { useIsLoggedIn } from "../hooks/user.hooks.ts";
import { logout } from "../utils/auth";

const Logout = () => {
  const isUserLoggedIn = useIsLoggedIn();
  useLayoutEffect(() => {
    if (isUserLoggedIn) {
      logout();
    }
  }, []);

  return <></>;
};

export default Logout;
